@charset 'utf-8';

@import './normalize'; // Reset styling
@import './helpers/bootstrap-grid'; // Bootstrap Grid

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f2f5 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-admin{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
    height: 80vh;

    .admin-login-form {
        max-width: 300px;
        margin: 60px auto;
    }
    .admin-login-form-forgot {
        float: right;
    }
    .admin-ant-col-rtl .login-form-forgot {
        float: left;
    }
    .admin-login-form-button {
        width: 100%;
        margin: 20px 0 0 0;
    }
}

.admin-layout-content{
    background: #fff;
    padding: 24px;
    min-height: 280px;
}

.admin-logo{
    transition: all .27s ease-in-out;
    width: 168px;
    margin: 16px;

    &.collapsed {
        visibility: hidden;
        opacity: 0;
    }

    img{
        display: block;
        width: 100%;
    }

    &.centered{
        width: 150px;
        margin: 11px auto 0 auto;
        text-align: center;
    }
}

.admin-layout-logo{
    background: url('/img/admin-logo.svg') center center no-repeat;
    background-size: cover;
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
}

.site-layout-background{
    background-color: #ffffff !important;
}

.card-form{
    margin-bottom: 15px !important;

    .ant-input, .ant-input-password{
        max-width: 550px;
    }

    .ant-btn{
        display: block;
        margin: 25px 0 5px 0;
    }
}

.container-fluid{
    &.no-padding{
        padding: 0;
    }
}

.input-group{
    margin: 10px 0;
}

.input-label{
    width: 100%;
    display: block;
    margin: 10px 0;
    font-size: 13px;
    font-weight: 500;

    &:first-child{
        margin-top: 0;
    }

    &.danger{
        color: $red;
    }
}

.ant-page-header{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.page-loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
}

.list-logo-img{
    width: 80px;
    object-fit: contain;
    object-position: center;
}

.editor-inpage{
    .codex-editor__redactor{
        margin: 0 !important;
    }
}